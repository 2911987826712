import React, { useEffect, useState } from 'react';
import {
  isKYCApp,
  LocalizePropType,
  selectUser,
  updateUser,
  withTextLocalizer,
} from 'getaway-data-layer';
import { useHistory } from 'react-router-dom';
import DynamicFormView from './DynamicFormView';
import { isDashboardScreen, navigateTo, showAlert } from '../../../../utils';
import { useQuery } from '../../../../hooks/useQuery';
import { useDispatch, useSelector } from 'react-redux';

interface Values {}

type Props = {
  localize: LocalizePropType;
};

function parseFormData(data) {
  const output = {};
  if (data && data.payload && data.payload.fields) {
    data.payload.fields.forEach((field) => {
      const key = field.title.replace(/([A-Z])/g, (letter) =>
        letter.toLowerCase()
      );
      const value = field.answer.value;
      console.log('value', value);
      if (!key?.includes('hidden') && !!value && value !== 'null') {
        output[key] = value;
      }
    });
  }

  return output;
}

const DynamicFormContainer = ({ localize }: Props) => {
  const history = useHistory();
  const query = useQuery();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const iframeUrl = query?.get('url');
  const [formId, setFormId] = useState('');
  const [page, setPage] = useState(1);

  const onSubmit = (formName, payload) => {
    const ebusProductOptions = { ...payload };
    delete ebusProductOptions?.['name oder kundennummer'];

    dispatch(
      updateUser(
        {
          extraData: JSON.stringify(
            user?.extraData
              ? { ...JSON.parse(user.extraData), ...payload }
              : payload
          ),
          ebusProductOptions: Object.values(
            user?.extraData
              ? { ...JSON.parse(user.extraData), ...ebusProductOptions }
              : ebusProductOptions
          ).flatMap((value) =>
            String(value)
              .split(',')
              .map((str) => str.trim())
          ),
        },
        () => {
          if (formName?.includes('Area/Partner')) {
            navigateTo(
              history,
              isDashboardScreen() ? '/dashboard/tariff' : '/onboarding/tariff'
            );
          } else {
            navigateTo(
              history,
              isDashboardScreen()
                ? '/settings'
                : isKYCApp()
                ? '/onboarding/IDOnboarding'
                : '/onboarding/addAddress'
            );
          }
        }
      )
    );
  };

  const onClose = () => {
    if (isDashboardScreen()) {
      navigateTo(history, '/settings');
    } else {
      showAlert(
        '',
        localize('cancel.FirstSteps.dialog.message'),
        localize('cancel.FirstSteps.dialog.yes'),
        () => navigateTo(history, isKYCApp() ? '/settings' : '/map'),
        localize('cancel.FirstSteps.dialog.no'),
        () => {},
        true
      );
    }
  };

  const handleIframeMessages = (event) => {
    if (typeof event.data === 'string') {
      try {
        const eventData = JSON.parse(event.data);
        if (eventData?.payload?.page !== undefined)
          setFormId(eventData?.payload?.formId);
        if (eventData?.payload?.page !== undefined)
          setPage(eventData?.payload?.page);
        if (eventData?.event?.includes('FormSubmitted')) {
          console.log('parseFormData', parseFormData(eventData));
          onSubmit(eventData?.payload?.formName, parseFormData(eventData));
        }
      } catch (error) {
        console.error('Error parsing message from iframe:', error);
      }
    } else {
      console.log('Received non-string message from iframe:', event.data);
    }
  };

  useEffect(() => {
    if (iframeUrl) {
      // @ts-ignore
      window.Tally.loadEmbeds();
      window.addEventListener('message', handleIframeMessages);
    }
    return () => {
      window.removeEventListener('message', handleIframeMessages);
    };
  }, []);

  return iframeUrl ? (
    <DynamicFormView
      localize={localize}
      formId={formId}
      page={page}
      iframeUrl={iframeUrl}
      onClose={onClose}
    />
  ) : null;
};

export default withTextLocalizer(DynamicFormContainer);
