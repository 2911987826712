export type Brand =
  | 'default'
  | 'ubeeqo'
  | 'robben-wientjes'
  | 'konig'
  | 'miet-smart';
export type TextGroup =
  | 'default'
  | 'ubeeqo'
  | 'robben-wientjes'
  | 'konig'
  | 'mietbar'
  | 'miet-smart'
  | 'book-n-drive'
  | 'teilauto'
  | 'cityflitzer'
  | 'franklin-mobil'
  | 'mvv-fahrpack'
  | 'connect'
  | 'sheepersharing'
  | 'my-motor';
export type ConfigState = {
  readonly googleApiAccessToken: string;
  readonly apiBaseURL: string;
  readonly apiVersion: string;
  readonly bundleId: string;
  readonly appleAppId: string;
  readonly xBrandId: string;
};
enum ConfigStoreTypes {
  SET_GOOGLE_API_ACCESS_TOKEN = 'config/SET_GOOGLE_API_ACCESS_TOKEN',
  SET_API_BASE_URL = 'config/SET_API_BASE_URL',
  SET_API_VERSION = 'config/SET_API_VERSION',
  SET_BUNDLE_ID = 'config/SET_BUNDLE_ID',
  SET_APPLE_APP_ID = 'config/SET_APPLE_APP_ID',
  SET_X_BRAND_ID = 'config/SET_X_BRAND_ID',
}
export default ConfigStoreTypes;
export type SetGoogleApiAccessToken = {
  type: ConfigStoreTypes.SET_GOOGLE_API_ACCESS_TOKEN;
  payload: {
    googleApiAccessToken: string;
  };
};
export type SetApiBaseURL = {
  type: ConfigStoreTypes.SET_API_BASE_URL;
  payload: {
    apiBaseURL: string;
  };
};
export type SetApiVersion = {
  type: ConfigStoreTypes.SET_API_VERSION;
  payload: {
    apiVersion: string;
  };
};
export type SetBundleId = {
  type: ConfigStoreTypes.SET_BUNDLE_ID;
  payload: {
    bundleId: string;
  };
};
export type SetAppleAppId = {
  type: ConfigStoreTypes.SET_APPLE_APP_ID;
  payload: {
    appleAppId: string;
  };
};
export type SetXBrandId = {
  type: ConfigStoreTypes.SET_X_BRAND_ID;
  payload: {
    xBrandId: string;
  };
};
export type ConfigAction =
  | SetGoogleApiAccessToken
  | SetApiBaseURL
  | SetApiVersion
  | SetBundleId
  | SetAppleAppId
  | SetXBrandId;
